<template>
  <div class="mr-5 ml-5 mt-4 fontStyle">
    <div>
      <div class="spinner">
        <b-spinner variant="success" type="grow" label="Spinning"></b-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import { getAccessTokenByAuthCode } from "../api/security/GetAccessTokenApi";
import { addSeconds } from "date-fns";

export default {
  name: "TenantAuthView",
  data() {
    return {
      addSeconds,
    };
  },
  methods: {
    getAccessToken(code) {
      getAccessTokenByAuthCode(code).then((response) => {
        console.log("getting access token");
        if (response === undefined) {
          const errMsg = "Error getting token, please check the logs";
          this.$router.push(`/ui/error/${errMsg}`);
        } else {
          localStorage.setItem("jwt", response.data.access_token);
          const tokenExpiry = this.addSeconds(
            Date.now(),
            response.data.expires_in
          );
          localStorage.setItem("tokenExpiry", tokenExpiry);
          this.$router.push("/ui");
          this.$root.$emit("decode_access_token", localStorage.getItem("jwt"));
        }
      });
    },
  },
  created() {
    const uri = window.location.search.substring(1);
    const params = new URLSearchParams(uri);
    if (params.get("code") != null) {
      const authCode = params.get("code");
      this.getAccessToken(authCode);
    } else {
      this.$router.push(`/ui/error/${params}`);
    }
  },
};
</script>

<style scoped>
.spinner {
  display: flex;
  align-self: center;
  align-content: center;
  align-items: center;
  justify-content: center;
}
</style>
