import { OCPP_AUTH_API } from "../common/http-common";
import qs from "querystring";

export function getAccessTokenByAuthCode(code) {
  const tenant = process.env.VUE_APP_TENANT;
  console.log(`${tenant}:: getting access Token`);
  const requestBody = {
    clientId: process.env.VUE_APP_CLIENT_ID,
    grantType: `authorization_code`,
    code: code,
    redirectUri: process.env.VUE_APP_REDIRECT_URI,
    scope: process.env.VUE_APP_SCOPE,
  };

  OCPP_AUTH_API.defaults.headers["Content-Type"] =
    "application/x-www-form-urlencoded";

  return OCPP_AUTH_API.post(
    `/${tenant}/oauth2/token`,
    qs.stringify(requestBody)
  ).catch(function (error) {
    console.log(error);
  });
}
